/// new

import React, { Fragment, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';

import Layout from '../components/Layout';
import GameNotReady from '../components/GameNotReady';
import pulp from '../assets/images/404pulp.webp'
import { Helmet } from 'react-helmet';

const cities = [
  'ba',
  'berlin',
]

const gamePath = 'juego'

const Page = (props) => {
  const { pathname } = props

  useEffect(() => {
    const data = window.dataLayer;
    const eventName = 'not_found';
    data.push({ event: eventName, page_location: pathname, referrer: document.referrer })
  })

  return (
    <Fragment>
      <Helmet
        title="Ooops ¿qué onda?"
      />
      <Grid container alignItems="center" justify="center" spacing={3}>
        <Grid item >
          <Box m={3}>
            <Typography align="center" variant="h3" component="h1" gutterBottom>¿Qué onda?</Typography>
            <img src={pulp} />
          </Box>
        </Grid>
      </Grid>
    </Fragment>
  )
};

const NotFoundPage = (props) => {
  const pathParts = props.location.pathname.split('/') 
  const isGame = ((pathParts[1] && cities.includes(pathParts[1])) && pathParts[2] && pathParts[2] === gamePath)
  return (<Layout {...props}>
    <>
      {!isGame && <Page pathname={props.location.pathname} />}
      {isGame && <GameNotReady />}
    </>
  </Layout>)
};

export default NotFoundPage
